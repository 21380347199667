import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import Hero from '../../../components/hero'
import Divisions from '../../../components/divisions'

export default ({ data, location }) => {
  const heroImage = data.heroImage
  const btnId = (location.state != null && location.state.btn != null) ? location.state.btn : "all"

  return (
    <>
      <Layout>
        <div id="sub-page">
        <SEO
            title="UNICOM Global divisions" 
            description="UNICOM Global is comprised of over 50 divisions. UNICOM's technology divisions are outlined below, categorized by industry type/sector." 
            url={data.site.siteMetadata.siteUrl + location.pathname} 
            image={data.site.siteMetadata.siteUrl + heroImage.childImageSharp.fluid.src}
          />
          <Hero
            class="intro-65"
            image={heroImage.childImageSharp.fluid}
            title="UNICOM Global Divisions"
            alt="office blocks against blue sky"
            subtitle="UNICOM Global consists of more than 50 corporate entities encompassing a wide range of businesses."
            type="root"
          />
        </div>

        <Divisions divisions={data.allContentfulDivision.edges} btnId={btnId} />
      </Layout>
    </>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }     
    allContentfulDivision(
      sort: { fields: [sequence], order: ASC }
      filter: { sector: { ne: "None" } }
    ) {
      edges {
        node {
          avatar {
            localFile {
              childImageSharp {
                fixed(width: 50, height: 50, quality: 90) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          sector: tag
          sectorDescription
          name
          url
        }
      }
    }
    heroImage: file(name: { eq: "divisions-banner" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
